import React from 'react'
import { StaticQuery, graphql } from 'gatsby'

export const ProductsContext = React.createContext()

/**
 * Wrapper to give Provider access to Sku nodes from Gatsby's GraphQL store.
 */
const ProductsProvider = ({ children }) => <StaticQuery query={skusQuery} render={(data) => <Provider data={data}>{children}</Provider>} />

/**
  Shares product information and availability through context.
  Products are first loaded from Gatsby's GraphQL store and then updated with
  current information from Stripe.
*/
const Provider = ({ data, children }) => {
  const shopifySkuMap = data.allShopifyProduct.nodes.reduce((map, shopifyProduct) => {
    if (shopifyProduct.variants[0] != undefined) {
      map[shopifyProduct.variants[0]?.sku] = shopifyProduct
    }
    return map
  }, {})

  const products = data.allContentfulSellableIngredient.nodes
    .filter((product) => {
      return product.name && product.priceCents &&
        shopifySkuMap[product.sku] && shopifySkuMap[product.sku].variants.length > 0
    })
    .map((product) => {
      const sku = shopifySkuMap[product.sku]

      return {
        name: product.name,
        brand: product.brand,
        quantityDescription: product.quantityDescription,
        sku: product.sku,
        priceCents: product.priceCents,
        picture: {
          fluid: {
            src: product.picture?.fluid?.src
          }
        },
        shopifyId: sku?.variants[0]?.shopifyId
      }
    })

  const findProduct = (sku) => {
    products.find((element) => {
      return element.sku === sku
    })
  }

  return <ProductsContext.Provider value={{ products, findProduct }}>{children}</ProductsContext.Provider>
}
const skusQuery = graphql`
  query skusQuery {
    allContentfulSellableIngredient {
      nodes {
        name
        brand
        sku
        priceCents
        quantityDescription
        slug
        picture {
          fluid(quality: 100, maxWidth:150) {
            src
          }
        }
      }
    }
    allShopifyProduct {
      nodes {
        id
        variants {
          sku
          shopifyId
          price
        }
      }
    }
  }
`

export default ProductsProvider
