import * as React from 'react'
import { Link } from 'gatsby'

import './components.scss'

const Footer: React.FC = ({ hideFooter }) => {
  return (
    <footer className={`footer container-fluid mt-5 pt-5 position-relative d-print-none bg-light ${hideFooter ? "d-none" : ""}`}>
      <div>
        <div className="row">
          <div className="col-12 col-md-2">
            <h3>EAT</h3>
            <h3>LESS</h3>
            <h3>MEAT</h3>
          </div>
          <div className="col-12 col-md-4">
            <p className="mb-0"><Link to="/about">About Us</Link></p>
            <div className="smaller">
              <p className="mb-0 lh-15px"><Link to="/privacy">Privacy</Link></p>
              <p className="mb-0 lh-15px"><Link to="/terms-of-service">Terms Of Service</Link></p>
              <p className="mb-0 lh-15px"><Link to="/cookie-policy">Cookie Policy</Link></p>
              <p className="mb-0 lh-15px"><Link to="/acceptable-use-policy">Acceptable Use Policy</Link></p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-8 pb-sm-5 small font-weight-light text-black-50">
            2020 ELM Foods All Rights Reserved.
          </div>
          <div className="col-12 col-sm-4 pb-5 small font-weight-light text-sm-right">
            <a className="text-black-50" href="mailto:hey@elmfoods.com">Get in touch &#128233;</a>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
