const shopifyKey = 'f111204743b9fb938248bd602849894f'

const shopifyApiCall = async (query) => {
  return fetch('https://shop.elmfoods.com/api/graphql.json', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/graphql',
      'X-Shopify-Storefront-Access-Token': shopifyKey
    },
    "body": query
  });
}

export const buildCart = async (cartItems, deliveryWindow, recipeSlugs) => {
  const cartJson = cartItems.map((cartItem) => {
    return `{ variantId: "${cartItem.item.shopifyId}", quantity: ${cartItem.quantity} }`
  })
  console.log(cartItems)
  const gqlMutationLineItems = `[${cartJson.join(",")}]`

  const mutation = `mutation {
                     checkoutCreate(input: {
                       lineItems: ${gqlMutationLineItems},
                       customAttributes: [
                         { key: "deliveryTime", value: "${deliveryWindow}"},
                         { key: "recipes", value: "${recipeSlugs.join(",")}"}
                        ]
                     }) {
                     checkout {
                       id,
                       webUrl
                     },
                   }
                 }`;

  const response = await shopifyApiCall(mutation);
  const { data } = await response.json()

  return data
}

export const getCartDetails = async (cartId) => {
  var query = `{
    node(id: "${cartId}") {
      id
      ... on Checkout {
        createdAt
        ready
        order {
          id
        }
        completedAt
      }
    }
  }`

  const response = await shopifyApiCall(query)
  const { data } = await response.json()

  return data
}
