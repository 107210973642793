import React, { useState, useEffect } from 'react'
import { getCartDetails } from '../../common/shopify'

export const CartContext = React.createContext()

const potentiallyClearOldCart = async (setContents, setRecipes) => {
  let cartId
  if (process.browser) {
    cartId = localStorage.getItem('shopifyCartId')
  }

  if (cartId) {
    const data = await getCartDetails(cartId)

    if (data?.node?.completedAt) {
      setContents([])
      setRecipes([])
    }

    localStorage.removeItem('shopifyCartId')
  }
}

const CartProvider = ({ children }) => {
  const [contents, setContents] = useState(() => {
    let localCart
    try {
      localCart = JSON.parse(localStorage.getItem('cart'))
    } catch (err) {
      console.error(err.message)
    }
    if (!localCart || !Array.isArray(localCart)) return []
    return localCart
  })

  const [recipes, setRecipes] = useState(() => {
    let local
    try {
      local = JSON.parse(localStorage.getItem('recipesInCart'))
    } catch (err) {
      console.error(err.message)
    }
    if (!local || !Array.isArray(local)) return []
    return local
  })

  useEffect(() => {
    try {
      localStorage.setItem('cart', JSON.stringify(contents))
      localStorage.setItem('recipesInCart', JSON.stringify(recipes))
    } catch (err) {
      console.error(err)
    }
  })

  const [visible, setVisibility] = useState(false)

  potentiallyClearOldCart(setContents, setRecipes)

  const set = (id, quantity) => {
    setContents((state) => {
      const index = state.findIndex((item) => item[0] === id)

      const newState = [...state]
      if (index !== -1) {
        newState[index] = [id, quantity]
      } else {
        newState.push([id, quantity])
      }
      return newState
    })
  }

  // Public functions
  const toggleCart = () => {
    setVisibility(!visible)
  }

  const remove = (id) => {
    setContents((state) => {
      return state.filter((item) => item[0] !== id)
    })
  }

  const cleanCart = (catalog) => {
    const newContents = contents.filter((item) => {
      const cartItem = catalog.products.find((catalogItem) => {
        return catalogItem.sku === item[0]
      })

      return cartItem != undefined
    })

    setContents(newContents)
  }

  const decorateCart = (catalog) => {
    const decoratedContent = contents.map((item) => {
      const catalogItem = catalog.products.find((catalogItem) => {
        return catalogItem.sku === item[0]
      })

      if (catalogItem) {
        return {
          item: catalogItem,
          quantity: Math.ceil(item[1])
        }
      }
    })

    return decoratedContent.filter(el => el != null)
  }

  const subtract = (id, quantity = 1) => {
    const currentItem = contents.find((item) => item[0] === id)
    const currentQuantity = currentItem ? currentItem[1] : 0
    if (currentQuantity - quantity <= 0) {
      remove(id)
    } else {
      set(id, currentQuantity - quantity)
    }
  }

  const add = (id, quantity = 1) => {
    const currentItem = contents.find((item) => item[0] === id)
    const currentQuantity = currentItem ? currentItem[1] : 0
    set(id, quantity + currentQuantity)
  }

  const clear = () => {
    setContents([])
    setRecipes([])
  }

  const numItems = () => {
    return contents.reduce((total, current) => {
      return total + Math.ceil(current[1])
    }, 0)
  }

  const total = () => {
    return contents.reduce((total, current) => {
      return total + Math.ceil(current[1])
    }, 0)
  }

  const contains = (ingredient) => {
    const index = contents.findIndex((item) => item[0] === ingredient.sku)

    return index != -1
  }

  const itemCount = (ingredient) => {
    const index = contents.findIndex((item) => item[0] === ingredient.sku)

    return index == -1 ? 0 : Math.ceil(contents[index][1])
  }

  const addRecipe = (recipeURI) => {
    let newRecipes = recipes
    const indexOf = recipes.indexOf(recipeURI)

    if (indexOf == -1) {
      newRecipes.push(recipeURI)
    }

    setRecipes(newRecipes)
  }

  const removeRecipe = (recipeURI) => {
    const newRecipes = recipes.filter((item) => item !== recipeURI)

    setRecipes(newRecipes)
  }

  const ctx = {
    contents,
    recipes,
    add,
    numItems,
    toggleCart,
    visible,
    clear,
    subtract,
    remove,
    cleanCart,
    decorateCart,
    total,
    itemCount,
    contains,
    addRecipe,
    removeRecipe
  }

  return <CartContext.Provider value={{ ...ctx }}>{children}</CartContext.Provider>
}

export default CartProvider
